import validate from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import set_45layout_45global from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/middleware/setLayout.global.ts";
export const globalMiddleware = [
  validate,
  set_45layout_45global
]
export const namedMiddleware = {
  auth: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/middleware/auth.ts"),
  "page-desktop": () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/middleware/pageDesktop.ts"),
  "page-mobile": () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/middleware/pageMobile.ts"),
  "un-auth": () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/middleware/unAuth.ts")
}