import { default as bankEHesAtmlxGMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/bank.vue?macro=true";
import { default as bank3OeX0uyjDeMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/deposit/bank.vue?macro=true";
import { default as cardp6XJXgLQqJMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/deposit/card.vue?macro=true";
import { default as codepayzpkBIcPRnHMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/deposit/codepay.vue?macro=true";
import { default as codepay2gkgk5WadKTMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/deposit/codepay2.vue?macro=true";
import { default as cryptoirPuj3g3k3Meta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/deposit/crypto.vue?macro=true";
import { default as momoiKF2Y1mcIdMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/deposit/momo.vue?macro=true";
import { default as betwmPOSQvvGhMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/history/bet.vue?macro=true";
import { default as transactionAsL2vLDX0cMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/history/transaction.vue?macro=true";
import { default as historylfP3BTxNuIMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/history.vue?macro=true";
import { default as profilegxt2IqoqY4Meta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/profile.vue?macro=true";
import { default as bankkQqhQYgHQcMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/withdraw/bank.vue?macro=true";
import { default as cardHRng2BGTSqMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/withdraw/card.vue?macro=true";
import { default as cryptoLw3H35dUsjMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/withdraw/crypto.vue?macro=true";
import { default as accountg6xDHZePdXMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account.vue?macro=true";
import { default as base_45buttonNFv9PACpwtMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-button.vue?macro=true";
import { default as base_45count_45downN4KvSwCM9QMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-count-down.vue?macro=true";
import { default as base_45dropdown_45radioQpAUg9IHoRMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-dropdown-radio.vue?macro=true";
import { default as base_45imgOBae75aAKkMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-img.vue?macro=true";
import { default as base_45input4tgGcytgONMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-input.vue?macro=true";
import { default as base_45loadingOC7A2cLtqJMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-loading.vue?macro=true";
import { default as base_45modal6Xa1gCIeCLMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-modal.vue?macro=true";
import { default as base_45money_45inputqfREzgTNv0Meta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-money-input.vue?macro=true";
import { default as base_45paginationlMi0jApMtrMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-pagination.vue?macro=true";
import { default as base_45select_45bankhWuhtH7TfBMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-select-bank.vue?macro=true";
import { default as game_45itemfAOjOAq7WXMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/game-item.vue?macro=true";
import { default as indexBnxijunnrlMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/index.vue?macro=true";
import { default as _91type_93SaugjPEXHPMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/game/[type].vue?macro=true";
import { default as game0x0XTBh3bKMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/game.vue?macro=true";
import { default as _91alias_93KKeYYymbcgMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/gioi-thieu/[alias].vue?macro=true";
import { default as gioi_45thieu8KPEDNkJudMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/gioi-thieu.vue?macro=true";
import { default as _91alias_93TIKVg4JKCvMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/huong-dan/[alias].vue?macro=true";
import { default as huong_45danD9UaM4UaAVMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/huong-dan.vue?macro=true";
import { default as indexWLWcxgRqg1Meta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/index.vue?macro=true";
import { default as _91id_93x0mNsFGknPMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/khuyen-mai/[id].vue?macro=true";
import { default as indexSMzK7wIgLXMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/khuyen-mai/index.vue?macro=true";
import { default as _91id_93m20QXvAMgrMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/khuyen-mai/the-loai/[id].vue?macro=true";
import { default as vipbYiHgVsi2HMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/khuyen-mai/vip.vue?macro=true";
import { default as khuyen_45mailpj6icyMUrMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/khuyen-mai.vue?macro=true";
import { default as _91type_93J913FbbmKeMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/livecasino/[type].vue?macro=true";
import { default as livecasinobRbNpmyr5yMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/livecasino.vue?macro=true";
import { default as reset_45password7CkpZiB38iMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/reset-password.vue?macro=true";
import { default as _91id_93kCDm7ebWc9Meta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/the-thao/[id].vue?macro=true";
import { default as indexHtgjQimIJcMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/the-thao/index.vue?macro=true";
import { default as indexFiBIB96NCuMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/the-thao/lich-thi-dau/index.vue?macro=true";
import { default as _91alias_931AsOQmhSOXMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/tin-tuc/[alias].vue?macro=true";
import { default as indexpJOHUMeqDSMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/tin-tuc/index.vue?macro=true";
import { default as _91Slug_93ZFi0JOnTyGMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/tin-tuc/the-loai/[Slug].vue?macro=true";
import { default as tin_45tuckX8R4cUxiJMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/tin-tuc.vue?macro=true";
import { default as verify_45mailK1jras7GgwMeta } from "/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/verify-mail.vue?macro=true";
export default [
  {
    name: accountg6xDHZePdXMeta?.name ?? "account",
    path: accountg6xDHZePdXMeta?.path ?? "/account",
    children: [
  {
    name: bankEHesAtmlxGMeta?.name ?? "account-bank",
    path: bankEHesAtmlxGMeta?.path ?? "bank",
    meta: bankEHesAtmlxGMeta || {},
    alias: bankEHesAtmlxGMeta?.alias || [],
    redirect: bankEHesAtmlxGMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/bank.vue").then(m => m.default || m)
  },
  {
    name: bank3OeX0uyjDeMeta?.name ?? "account-deposit-bank",
    path: bank3OeX0uyjDeMeta?.path ?? "deposit/bank",
    meta: bank3OeX0uyjDeMeta || {},
    alias: bank3OeX0uyjDeMeta?.alias || [],
    redirect: bank3OeX0uyjDeMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/deposit/bank.vue").then(m => m.default || m)
  },
  {
    name: cardp6XJXgLQqJMeta?.name ?? "account-deposit-card",
    path: cardp6XJXgLQqJMeta?.path ?? "deposit/card",
    meta: cardp6XJXgLQqJMeta || {},
    alias: cardp6XJXgLQqJMeta?.alias || [],
    redirect: cardp6XJXgLQqJMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/deposit/card.vue").then(m => m.default || m)
  },
  {
    name: codepayzpkBIcPRnHMeta?.name ?? "account-deposit-codepay",
    path: codepayzpkBIcPRnHMeta?.path ?? "deposit/codepay",
    meta: codepayzpkBIcPRnHMeta || {},
    alias: codepayzpkBIcPRnHMeta?.alias || [],
    redirect: codepayzpkBIcPRnHMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/deposit/codepay.vue").then(m => m.default || m)
  },
  {
    name: codepay2gkgk5WadKTMeta?.name ?? "account-deposit-codepay2",
    path: codepay2gkgk5WadKTMeta?.path ?? "deposit/codepay2",
    meta: codepay2gkgk5WadKTMeta || {},
    alias: codepay2gkgk5WadKTMeta?.alias || [],
    redirect: codepay2gkgk5WadKTMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/deposit/codepay2.vue").then(m => m.default || m)
  },
  {
    name: cryptoirPuj3g3k3Meta?.name ?? "account-deposit-crypto",
    path: cryptoirPuj3g3k3Meta?.path ?? "deposit/crypto",
    meta: cryptoirPuj3g3k3Meta || {},
    alias: cryptoirPuj3g3k3Meta?.alias || [],
    redirect: cryptoirPuj3g3k3Meta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/deposit/crypto.vue").then(m => m.default || m)
  },
  {
    name: momoiKF2Y1mcIdMeta?.name ?? "account-deposit-momo",
    path: momoiKF2Y1mcIdMeta?.path ?? "deposit/momo",
    meta: momoiKF2Y1mcIdMeta || {},
    alias: momoiKF2Y1mcIdMeta?.alias || [],
    redirect: momoiKF2Y1mcIdMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/deposit/momo.vue").then(m => m.default || m)
  },
  {
    name: historylfP3BTxNuIMeta?.name ?? "account-history",
    path: historylfP3BTxNuIMeta?.path ?? "history",
    children: [
  {
    name: betwmPOSQvvGhMeta?.name ?? "account-history-bet",
    path: betwmPOSQvvGhMeta?.path ?? "bet",
    meta: betwmPOSQvvGhMeta || {},
    alias: betwmPOSQvvGhMeta?.alias || [],
    redirect: betwmPOSQvvGhMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/history/bet.vue").then(m => m.default || m)
  },
  {
    name: transactionAsL2vLDX0cMeta?.name ?? "account-history-transaction",
    path: transactionAsL2vLDX0cMeta?.path ?? "transaction",
    meta: transactionAsL2vLDX0cMeta || {},
    alias: transactionAsL2vLDX0cMeta?.alias || [],
    redirect: transactionAsL2vLDX0cMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/history/transaction.vue").then(m => m.default || m)
  }
],
    meta: historylfP3BTxNuIMeta || {},
    alias: historylfP3BTxNuIMeta?.alias || [],
    redirect: historylfP3BTxNuIMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: profilegxt2IqoqY4Meta?.name ?? "account-profile",
    path: profilegxt2IqoqY4Meta?.path ?? "profile",
    meta: profilegxt2IqoqY4Meta || {},
    alias: profilegxt2IqoqY4Meta?.alias || [],
    redirect: profilegxt2IqoqY4Meta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: bankkQqhQYgHQcMeta?.name ?? "account-withdraw-bank",
    path: bankkQqhQYgHQcMeta?.path ?? "withdraw/bank",
    meta: bankkQqhQYgHQcMeta || {},
    alias: bankkQqhQYgHQcMeta?.alias || [],
    redirect: bankkQqhQYgHQcMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/withdraw/bank.vue").then(m => m.default || m)
  },
  {
    name: cardHRng2BGTSqMeta?.name ?? "account-withdraw-card",
    path: cardHRng2BGTSqMeta?.path ?? "withdraw/card",
    meta: cardHRng2BGTSqMeta || {},
    alias: cardHRng2BGTSqMeta?.alias || [],
    redirect: cardHRng2BGTSqMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/withdraw/card.vue").then(m => m.default || m)
  },
  {
    name: cryptoLw3H35dUsjMeta?.name ?? "account-withdraw-crypto",
    path: cryptoLw3H35dUsjMeta?.path ?? "withdraw/crypto",
    meta: cryptoLw3H35dUsjMeta || {},
    alias: cryptoLw3H35dUsjMeta?.alias || [],
    redirect: cryptoLw3H35dUsjMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account/withdraw/crypto.vue").then(m => m.default || m)
  }
],
    meta: accountg6xDHZePdXMeta || {},
    alias: accountg6xDHZePdXMeta?.alias || [],
    redirect: accountg6xDHZePdXMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: base_45buttonNFv9PACpwtMeta?.name ?? "dev-base-button",
    path: base_45buttonNFv9PACpwtMeta?.path ?? "/dev/base-button",
    meta: base_45buttonNFv9PACpwtMeta || {},
    alias: base_45buttonNFv9PACpwtMeta?.alias || [],
    redirect: base_45buttonNFv9PACpwtMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-button.vue").then(m => m.default || m)
  },
  {
    name: base_45count_45downN4KvSwCM9QMeta?.name ?? "dev-base-count-down",
    path: base_45count_45downN4KvSwCM9QMeta?.path ?? "/dev/base-count-down",
    meta: base_45count_45downN4KvSwCM9QMeta || {},
    alias: base_45count_45downN4KvSwCM9QMeta?.alias || [],
    redirect: base_45count_45downN4KvSwCM9QMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-count-down.vue").then(m => m.default || m)
  },
  {
    name: base_45dropdown_45radioQpAUg9IHoRMeta?.name ?? "dev-base-dropdown-radio",
    path: base_45dropdown_45radioQpAUg9IHoRMeta?.path ?? "/dev/base-dropdown-radio",
    meta: base_45dropdown_45radioQpAUg9IHoRMeta || {},
    alias: base_45dropdown_45radioQpAUg9IHoRMeta?.alias || [],
    redirect: base_45dropdown_45radioQpAUg9IHoRMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-dropdown-radio.vue").then(m => m.default || m)
  },
  {
    name: base_45imgOBae75aAKkMeta?.name ?? "dev-base-img",
    path: base_45imgOBae75aAKkMeta?.path ?? "/dev/base-img",
    meta: base_45imgOBae75aAKkMeta || {},
    alias: base_45imgOBae75aAKkMeta?.alias || [],
    redirect: base_45imgOBae75aAKkMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-img.vue").then(m => m.default || m)
  },
  {
    name: base_45input4tgGcytgONMeta?.name ?? "dev-base-input",
    path: base_45input4tgGcytgONMeta?.path ?? "/dev/base-input",
    meta: base_45input4tgGcytgONMeta || {},
    alias: base_45input4tgGcytgONMeta?.alias || [],
    redirect: base_45input4tgGcytgONMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-input.vue").then(m => m.default || m)
  },
  {
    name: base_45loadingOC7A2cLtqJMeta?.name ?? "dev-base-loading",
    path: base_45loadingOC7A2cLtqJMeta?.path ?? "/dev/base-loading",
    meta: base_45loadingOC7A2cLtqJMeta || {},
    alias: base_45loadingOC7A2cLtqJMeta?.alias || [],
    redirect: base_45loadingOC7A2cLtqJMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-loading.vue").then(m => m.default || m)
  },
  {
    name: base_45modal6Xa1gCIeCLMeta?.name ?? "dev-base-modal",
    path: base_45modal6Xa1gCIeCLMeta?.path ?? "/dev/base-modal",
    meta: base_45modal6Xa1gCIeCLMeta || {},
    alias: base_45modal6Xa1gCIeCLMeta?.alias || [],
    redirect: base_45modal6Xa1gCIeCLMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-modal.vue").then(m => m.default || m)
  },
  {
    name: base_45money_45inputqfREzgTNv0Meta?.name ?? "dev-base-money-input",
    path: base_45money_45inputqfREzgTNv0Meta?.path ?? "/dev/base-money-input",
    meta: base_45money_45inputqfREzgTNv0Meta || {},
    alias: base_45money_45inputqfREzgTNv0Meta?.alias || [],
    redirect: base_45money_45inputqfREzgTNv0Meta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-money-input.vue").then(m => m.default || m)
  },
  {
    name: base_45paginationlMi0jApMtrMeta?.name ?? "dev-base-pagination",
    path: base_45paginationlMi0jApMtrMeta?.path ?? "/dev/base-pagination",
    meta: base_45paginationlMi0jApMtrMeta || {},
    alias: base_45paginationlMi0jApMtrMeta?.alias || [],
    redirect: base_45paginationlMi0jApMtrMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-pagination.vue").then(m => m.default || m)
  },
  {
    name: base_45select_45bankhWuhtH7TfBMeta?.name ?? "dev-base-select-bank",
    path: base_45select_45bankhWuhtH7TfBMeta?.path ?? "/dev/base-select-bank",
    meta: base_45select_45bankhWuhtH7TfBMeta || {},
    alias: base_45select_45bankhWuhtH7TfBMeta?.alias || [],
    redirect: base_45select_45bankhWuhtH7TfBMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/base-select-bank.vue").then(m => m.default || m)
  },
  {
    name: game_45itemfAOjOAq7WXMeta?.name ?? "dev-game-item",
    path: game_45itemfAOjOAq7WXMeta?.path ?? "/dev/game-item",
    meta: game_45itemfAOjOAq7WXMeta || {},
    alias: game_45itemfAOjOAq7WXMeta?.alias || [],
    redirect: game_45itemfAOjOAq7WXMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/game-item.vue").then(m => m.default || m)
  },
  {
    name: indexBnxijunnrlMeta?.name ?? "dev",
    path: indexBnxijunnrlMeta?.path ?? "/dev",
    meta: indexBnxijunnrlMeta || {},
    alias: indexBnxijunnrlMeta?.alias || [],
    redirect: indexBnxijunnrlMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: game0x0XTBh3bKMeta?.name ?? "game",
    path: game0x0XTBh3bKMeta?.path ?? "/game",
    children: [
  {
    name: _91type_93SaugjPEXHPMeta?.name ?? "game-type",
    path: _91type_93SaugjPEXHPMeta?.path ?? ":type",
    meta: _91type_93SaugjPEXHPMeta || {},
    alias: _91type_93SaugjPEXHPMeta?.alias || [],
    redirect: _91type_93SaugjPEXHPMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/game/[type].vue").then(m => m.default || m)
  }
],
    meta: game0x0XTBh3bKMeta || {},
    alias: game0x0XTBh3bKMeta?.alias || [],
    redirect: game0x0XTBh3bKMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/game.vue").then(m => m.default || m)
  },
  {
    name: gioi_45thieu8KPEDNkJudMeta?.name ?? "gioi-thieu",
    path: gioi_45thieu8KPEDNkJudMeta?.path ?? "/gioi-thieu",
    children: [
  {
    name: _91alias_93KKeYYymbcgMeta?.name ?? "gioi-thieu-alias",
    path: _91alias_93KKeYYymbcgMeta?.path ?? ":alias",
    meta: _91alias_93KKeYYymbcgMeta || {},
    alias: _91alias_93KKeYYymbcgMeta?.alias || [],
    redirect: _91alias_93KKeYYymbcgMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/gioi-thieu/[alias].vue").then(m => m.default || m)
  }
],
    meta: gioi_45thieu8KPEDNkJudMeta || {},
    alias: gioi_45thieu8KPEDNkJudMeta?.alias || [],
    redirect: gioi_45thieu8KPEDNkJudMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/gioi-thieu.vue").then(m => m.default || m)
  },
  {
    name: huong_45danD9UaM4UaAVMeta?.name ?? "huong-dan",
    path: huong_45danD9UaM4UaAVMeta?.path ?? "/huong-dan",
    children: [
  {
    name: _91alias_93TIKVg4JKCvMeta?.name ?? "huong-dan-alias",
    path: _91alias_93TIKVg4JKCvMeta?.path ?? ":alias",
    meta: _91alias_93TIKVg4JKCvMeta || {},
    alias: _91alias_93TIKVg4JKCvMeta?.alias || [],
    redirect: _91alias_93TIKVg4JKCvMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/huong-dan/[alias].vue").then(m => m.default || m)
  }
],
    meta: huong_45danD9UaM4UaAVMeta || {},
    alias: huong_45danD9UaM4UaAVMeta?.alias || [],
    redirect: huong_45danD9UaM4UaAVMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/huong-dan.vue").then(m => m.default || m)
  },
  {
    name: indexWLWcxgRqg1Meta?.name ?? "index",
    path: indexWLWcxgRqg1Meta?.path ?? "/",
    meta: indexWLWcxgRqg1Meta || {},
    alias: indexWLWcxgRqg1Meta?.alias || [],
    redirect: indexWLWcxgRqg1Meta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/index.vue").then(m => m.default || m)
  },
  {
    path: khuyen_45mailpj6icyMUrMeta?.path ?? "/khuyen-mai",
    children: [
  {
    name: _91id_93x0mNsFGknPMeta?.name ?? "khuyen-mai-id",
    path: _91id_93x0mNsFGknPMeta?.path ?? ":id",
    meta: _91id_93x0mNsFGknPMeta || {},
    alias: _91id_93x0mNsFGknPMeta?.alias || [],
    redirect: _91id_93x0mNsFGknPMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/khuyen-mai/[id].vue").then(m => m.default || m)
  },
  {
    name: indexSMzK7wIgLXMeta?.name ?? "khuyen-mai",
    path: indexSMzK7wIgLXMeta?.path ?? "",
    meta: indexSMzK7wIgLXMeta || {},
    alias: indexSMzK7wIgLXMeta?.alias || [],
    redirect: indexSMzK7wIgLXMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/khuyen-mai/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93m20QXvAMgrMeta?.name ?? "khuyen-mai-the-loai-id",
    path: _91id_93m20QXvAMgrMeta?.path ?? "the-loai/:id",
    meta: _91id_93m20QXvAMgrMeta || {},
    alias: _91id_93m20QXvAMgrMeta?.alias || [],
    redirect: _91id_93m20QXvAMgrMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/khuyen-mai/the-loai/[id].vue").then(m => m.default || m)
  },
  {
    name: vipbYiHgVsi2HMeta?.name ?? "khuyen-mai-vip",
    path: vipbYiHgVsi2HMeta?.path ?? "vip",
    meta: vipbYiHgVsi2HMeta || {},
    alias: vipbYiHgVsi2HMeta?.alias || [],
    redirect: vipbYiHgVsi2HMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/khuyen-mai/vip.vue").then(m => m.default || m)
  }
],
    name: khuyen_45mailpj6icyMUrMeta?.name ?? undefined,
    meta: khuyen_45mailpj6icyMUrMeta || {},
    alias: khuyen_45mailpj6icyMUrMeta?.alias || [],
    redirect: khuyen_45mailpj6icyMUrMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/khuyen-mai.vue").then(m => m.default || m)
  },
  {
    name: livecasinobRbNpmyr5yMeta?.name ?? "livecasino",
    path: livecasinobRbNpmyr5yMeta?.path ?? "/livecasino",
    children: [
  {
    name: _91type_93J913FbbmKeMeta?.name ?? "livecasino-type",
    path: _91type_93J913FbbmKeMeta?.path ?? ":type",
    meta: _91type_93J913FbbmKeMeta || {},
    alias: _91type_93J913FbbmKeMeta?.alias || [],
    redirect: _91type_93J913FbbmKeMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/livecasino/[type].vue").then(m => m.default || m)
  }
],
    meta: livecasinobRbNpmyr5yMeta || {},
    alias: livecasinobRbNpmyr5yMeta?.alias || [],
    redirect: livecasinobRbNpmyr5yMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/livecasino.vue").then(m => m.default || m)
  },
  {
    name: reset_45password7CkpZiB38iMeta?.name ?? "reset-password",
    path: reset_45password7CkpZiB38iMeta?.path ?? "/reset-password",
    meta: reset_45password7CkpZiB38iMeta || {},
    alias: reset_45password7CkpZiB38iMeta?.alias || [],
    redirect: reset_45password7CkpZiB38iMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kCDm7ebWc9Meta?.name ?? "the-thao-id",
    path: _91id_93kCDm7ebWc9Meta?.path ?? "/the-thao/:id",
    meta: _91id_93kCDm7ebWc9Meta || {},
    alias: _91id_93kCDm7ebWc9Meta?.alias || [],
    redirect: _91id_93kCDm7ebWc9Meta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/the-thao/[id].vue").then(m => m.default || m)
  },
  {
    name: indexHtgjQimIJcMeta?.name ?? "the-thao",
    path: indexHtgjQimIJcMeta?.path ?? "/the-thao",
    meta: indexHtgjQimIJcMeta || {},
    alias: indexHtgjQimIJcMeta?.alias || [],
    redirect: indexHtgjQimIJcMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/the-thao/index.vue").then(m => m.default || m)
  },
  {
    name: indexFiBIB96NCuMeta?.name ?? "the-thao-lich-thi-dau",
    path: indexFiBIB96NCuMeta?.path ?? "/the-thao/lich-thi-dau",
    meta: indexFiBIB96NCuMeta || {},
    alias: indexFiBIB96NCuMeta?.alias || [],
    redirect: indexFiBIB96NCuMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/the-thao/lich-thi-dau/index.vue").then(m => m.default || m)
  },
  {
    path: tin_45tuckX8R4cUxiJMeta?.path ?? "/tin-tuc",
    children: [
  {
    name: _91alias_931AsOQmhSOXMeta?.name ?? "tin-tuc-alias",
    path: _91alias_931AsOQmhSOXMeta?.path ?? ":alias",
    meta: _91alias_931AsOQmhSOXMeta || {},
    alias: _91alias_931AsOQmhSOXMeta?.alias || [],
    redirect: _91alias_931AsOQmhSOXMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/tin-tuc/[alias].vue").then(m => m.default || m)
  },
  {
    name: indexpJOHUMeqDSMeta?.name ?? "tin-tuc",
    path: indexpJOHUMeqDSMeta?.path ?? "",
    meta: indexpJOHUMeqDSMeta || {},
    alias: indexpJOHUMeqDSMeta?.alias || [],
    redirect: indexpJOHUMeqDSMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: _91Slug_93ZFi0JOnTyGMeta?.name ?? "tin-tuc-the-loai-Slug",
    path: _91Slug_93ZFi0JOnTyGMeta?.path ?? "the-loai/:Slug",
    meta: _91Slug_93ZFi0JOnTyGMeta || {},
    alias: _91Slug_93ZFi0JOnTyGMeta?.alias || [],
    redirect: _91Slug_93ZFi0JOnTyGMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/tin-tuc/the-loai/[Slug].vue").then(m => m.default || m)
  }
],
    name: tin_45tuckX8R4cUxiJMeta?.name ?? undefined,
    meta: tin_45tuckX8R4cUxiJMeta || {},
    alias: tin_45tuckX8R4cUxiJMeta?.alias || [],
    redirect: tin_45tuckX8R4cUxiJMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/tin-tuc.vue").then(m => m.default || m)
  },
  {
    name: verify_45mailK1jras7GgwMeta?.name ?? "verify-mail",
    path: verify_45mailK1jras7GgwMeta?.path ?? "/verify-mail",
    meta: verify_45mailK1jras7GgwMeta || {},
    alias: verify_45mailK1jras7GgwMeta?.alias || [],
    redirect: verify_45mailK1jras7GgwMeta?.redirect || undefined,
    component: () => import("/Users/rio-dev/Documents/Projects/Brand/new-project/ps23-web/pages/verify-mail.vue").then(m => m.default || m)
  }
]